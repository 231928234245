/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

window.$ = window.jQuery = require("jquery");
require("slick-carousel");
require("../bootstrap");
require("./sidebar");

$(window).on("scroll", function () {
    var scrollTop = $(this).scrollTop();
    if (scrollTop > 60) {
        $("body").addClass("scrolled");
    } else {
        $("body").removeClass("scrolled");
    }
});

$(function () {
    $("html, body").removeClass("is-loading");
    setTimeout(() => {
        $(".loading-box").fadeOut(300, function () {
            $(this).remove();
        });
    }, 100);

    const reviews = $(".reviews-slider");
    reviews.slick({
        slidesToShow: 3,
        dots: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    arrows: false,
                },
            },
        ],
    });
});

window.formateDate = (d) => {
    let date = new Date(d).toLocaleDateString("en-us", {
        day: "numeric",
    });
    let month = new Date(d).toLocaleDateString("en-us", {
        month: "long",
    });
    let year = new Date(d).toLocaleDateString("en-us", {
        year: "numeric",
    });
    return `${date} ${month} ${year}`;
};

$("body").on("submit", "#contact-form", function (e) {
    var btn = $(this).find("button");
    var inputs = $(this).find("input, textarea");
    btn.prop("disabled", true);

    e.preventDefault();

    var form = $(this);
    var url = form.attr("action");
    console.log(form.serialize());
    $.ajax({
        type: "POST",
        url: url,
        data: form.serialize(),
        success: function (data) {
            console.log(data);
            $("#thanks-contact-modal").modal("show");
            btn.prop("disabled", false);
            inputs.val("");
        },
    });
});
